import React, { useEffect, useMemo, useState } from 'react';
import { MdNorth } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { throttle } from 'lodash';

const Footer = () => {
  const [wheel, SetWheel] = useState(false);
  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        if (window.scrollY !== 0) {
          SetWheel(true);
        } else {
          SetWheel(false);
        }
      }, 100),
    [wheel],
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);
  return (
    <footer className="footer">
      <div className="inner">
        <div className="top">
          <div className="left">
            <div className="about">
              <Link to="https://www.kiwontech.com/" target="_blank" rel="noreferrer">
                <span className="bigfont">회사소개</span>
              </Link>
            </div>

            <div className="contact">
              <Link to="/customer/product-inquiry/contact-us">
                <span className="bigfont">문의하기</span>
              </Link>
            </div>
          </div>

          <div className="right">
            <div className="privacy">
              <button
                type="link"
                onClick={() => {
                  window.open('/popup/term-personal', 'window', 'width=500, height=700, location=no, status=no, scrollbars=yes');
                }}
              >
                개인정보처리방침
              </button>
            </div>

            <div className="collection">
              <button
                type="link"
                onClick={() => {
                  window.open('/popup/term-email', 'window', 'width=500, height=600, location=no, status=no, scrollbars=yes');
                }}
              >
                이메일주소무단수집거부
              </button>
            </div>
          </div>
        </div>
        <ul className="bottom">
          <li>
            서울특별시 구로구 디지털로31길 62 <br className="w480" />
            에이스아티스포럼 1001~1006호 <br className="w900" />
            <span className="w480"></span>
            <span className="w1000">/</span> 부산광역시 부산진구 범일로176 로얄팰리스 2차 702호
          </li>
          <li className="flex">
            <p>
              <span>TEL.</span>02.6012.7406
            </p>
            <p>
              <span>FAX.</span>02.6085.4330
            </p>
            <p>
              <span>EMAIL.</span>119@egplatforms.com
            </p>
          </li>
        </ul>
        <div className="copy">
          <p>COPYRIGHT &copy; 2024 kiwontech all rights reserved.</p>
        </div>
        <div className="toTop" onClick={onTop}>
          <div className={wheel ? 'totop active' : 'totop'}>
            <MdNorth />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
